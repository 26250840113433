import "@/styles/globals.css"
import "@/styles/theme.css"
import "primereact/resources/primereact.min.css"
import type { AppProps } from "next/app"
import { ChakraProvider } from "@chakra-ui/react"
import { SessionProvider } from "next-auth/react"
import { Suspense } from "react"
import Loading from "@/components/Loading"
import { QueryClient, QueryClientProvider } from "react-query"

import { extendTheme } from "@chakra-ui/react"
import ProgressNotification from "@/components/ProgressNotification"
import { SettingsProvider } from "@/hooks/useSettings"
import { Provider } from "react-redux"
import store from "@/app/store"

const theme = extendTheme({
  components: {
    Drawer: {
      variants: {
        permanent: {
          dialog: {
            pointerEvents: "auto",
          },
          dialogContainer: {
            pointerEvents: "none",
          },
        },
      },
    },
  },
})

const queryClient = new QueryClient()

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SessionProvider session={session}>
            <SettingsProvider>
              <Suspense fallback={<Loading />}>
                <Component {...pageProps} />
                <ProgressNotification />
              </Suspense>
            </SettingsProvider>
          </SessionProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </Provider>
  )
}
