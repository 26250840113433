import { useState, useEffect, useRef, MutableRefObject } from "react"
import { ProgressBar } from "primereact/progressbar"
import { Toast } from "primereact/toast"
import { getUserTasks } from "@/utils/api"
import { WasteGroupProgress } from "@/utils/tasks"
import { Box, Text, useToast } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { useQuery } from "react-query"
import { RootState } from "@/app/store"
import { useDispatch, useSelector } from "react-redux"
import { setIsSyncing } from "@/app/store/appSlice"

function ProgressIndicator({
  toast,
}: {
  toast: MutableRefObject<Toast | null>
}) {
  const dispatch = useDispatch()
  const chakraToast = useToast()

  const [progress, setProgress] = useState<number>(0)
  const { status } = useSession()
  const errorCount = useRef(0)

  useEffect(() => {
    const interval = setInterval(async () => {
      if (status !== "authenticated") {
        clearInterval(interval)
        return
      }
      try {
        const { data } = await getUserTasks()

        const tasks = data.tasks
        errorCount.current = 0
        dispatch(setIsSyncing(true))

        // Calculate total progress across all tasks
        let totalProgress = 0
        let totalCount = 0
        for (const taskId in tasks) {
          const taskProgresses: WasteGroupProgress[] = tasks[taskId]
          for (const progress of taskProgresses) {
            totalProgress += progress.progress
            totalCount++
          }
        }
        const averageProgress = totalCount > 0 ? totalProgress / totalCount : 0

        // Update progress
        setProgress(averageProgress)

        // If progress is 100, wait for 2 seconds and then clear the toast notification
        if (averageProgress === 100) {
          dispatch(setIsSyncing(false))
          chakraToast({
            title: "Synkronointi valmis",
            description: "Ole hyvä ja päivitä sivu",
            status: "success",
            duration: 4000,
          })
          const event = new Event("visibilitychange")
          document.dispatchEvent(event)
          setTimeout(() => {
            toast?.current?.clear()
          }, 4000)
        }
      } catch (error) {
        dispatch(setIsSyncing(false))
        errorCount.current++
        if (errorCount.current > 5) {
          toast?.current?.clear()
        }
      }
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [chakraToast, dispatch, status, toast])

  return (
    <Box w="100%">
      <Box mb="10px">
        <Text fontWeight={"bold"} mb="5px" color={"black"}>
          Synkronoidaan dataa...
        </Text>
        <ProgressBar
          color="var(--darkGreen)"
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      </Box>

      <ProgressBar
        color="var(--darkGreen)"
        value={progress}
        showValue={false}
      />
    </Box>
  )
}

export default function ProgressNotification() {
  const toast = useRef<Toast | null>(null)
  const shown = useRef<boolean>(false)

  const { isFetching } = useSelector((state: RootState) => state.app)

  const { status } = useSession()

  const { data } = useQuery(
    "tasks",
    async () => {
      const { data } = await getUserTasks()
      return data
    },
    {
      refetchInterval: 5000,
      enabled: status === "authenticated",
    }
  )

  if (data?.tasks && !shown.current && !isFetching) {
    if (
      Object.values(data.tasks)
        .flat()
        .some((progress) => progress.progress < 100)
    ) {
      shown.current = true
      toast?.current?.show({
        severity: "success",
        summary: "Syncing",
        sticky: true,
        contentStyle: {
          backgroundColor: "#FFF",
          borderWidth: "0px",
        },
        content: <ProgressIndicator toast={toast} />,
      })
    } else {
      shown.current = false
    }
  }

  return <Toast baseZIndex={99999} position="bottom-right" ref={toast}></Toast>
}
