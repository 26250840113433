import { useBreakpointValue } from "@chakra-ui/react"
import { useRouter } from "next/router"
import React, { useCallback, useContext, useEffect } from "react"

export const useSettings = () => {
  return useContext(SettingsContext)
}

interface setttingsContext {
  drawerOpen: boolean
  onDrawerClose: () => void
  onDrawerOpen: () => void
}

export const SettingsContext = React.createContext<setttingsContext>(
  {} as setttingsContext
)

export const SettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const defaultOpen = useBreakpointValue({ base: false, xl: true })

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const onDrawerClose = () => setDrawerOpen(false)

  const onDrawerOpen = () => setDrawerOpen(true)

  useEffect(() => {
    if (defaultOpen === undefined) return
    setDrawerOpen(defaultOpen)
  }, [defaultOpen])

  const router = useRouter()

  const handleRouteChange = useCallback(() => {
    defaultOpen === false && setDrawerOpen(false)
  }, [defaultOpen])

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [handleRouteChange, router.events])

  return (
    <SettingsContext.Provider
      value={{ drawerOpen, onDrawerClose, onDrawerOpen }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
