import { WasteGroup } from "@prisma/client"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AppState {
  isFetching: boolean
  isSyncing: boolean
  selectedWasteGroup?: WasteGroup
}

const initialState: AppState = {
  isFetching: false,
  isSyncing: false,
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    setIsSyncing: (state, action: PayloadAction<boolean>) => {
      state.isSyncing = action.payload
    },
    setSelectedWasteGroup: (state, action: PayloadAction<WasteGroup>) => {
      state.selectedWasteGroup = action.payload
    },
  },
})

export const { setIsFetching, setIsSyncing, setSelectedWasteGroup } =
  appSlice.actions

export default appSlice.reducer
