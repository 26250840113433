import { Flex, Spinner } from "@chakra-ui/react"

const Loading = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex
      h="100vh"
      w="100%"
      justify={"center"}
      align="center"
      direction={"column"}
    >
      {children}
      <LoadingIndicator />
    </Flex>
  )
}

export const LoadingIndicator = () => {
  return (
    <Spinner
      thickness="5px"
      speed="0.65s"
      emptyColor="gray.200"
      color="var(--darkGreen)"
      size="xl"
      boxSize={{ base: "40px", md: "80px" }}
    />
  )
}

export default Loading
