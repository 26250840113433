import { SyncProgressResponse } from "@/pages/api/sync/progress"
import { UserTasksResponse } from "@/pages/api/user/tasks"
import { WasteAverageResponse } from "@/pages/api/waste-data/waste-average"
import { WasteCompaniesResponse } from "@/pages/api/waste-data/waste-companies"
import { WasteDataPanelResponse } from "@/pages/api/waste-data/waste-data-panel"
import { WasteGroupResponse } from "@/pages/api/waste-data/waste-groups"
import { WastePerCustomIntervalResponse } from "@/pages/api/waste-data/waste-per-custom-interval"
import { WastePerMonthResponse } from "@/pages/api/waste-data/waste-per-month"
import axios from "axios"

export function getMonthlyRateByGroupID(groupID?: number) {
  return axios.get<WastePerMonthResponse>(
    `/api/waste-data/waste-per-month?groupId=${groupID}`
  )
}

export function getAverage(wasteType?: string) {
  return axios.get<WasteAverageResponse>(
    `/api/waste-data/waste-average${wasteType ? `?wasteType=${wasteType}` : ""}`
  )
}

export function getCustomIntervalRateByGroupID(
  groupID?: number,
  from?: Date,
  to?: Date
) {
  const fromDate = from ? from.toISOString() : new Date().toISOString()
  const toDate = to ? to.toISOString() : new Date().toISOString()
  return axios.get<WastePerCustomIntervalResponse>(
    `/api/waste-data/waste-per-custom-interval?groupId=${groupID}&from=${fromDate}&to=${toDate}`
  )
}

export function getWasteCompanies() {
  return axios.get<WasteCompaniesResponse>(`/api/waste-data/waste-companies`)
}

export function getWasteGroups() {
  return axios.get<WasteGroupResponse>(`/api/waste-data/waste-groups`)
}

export function getWastePanelData(wasteGroup?: string, startDate?: Date) {
  return axios.get<WasteDataPanelResponse>(
    `/api/waste-data/waste-data-panel?wasteGroup=${wasteGroup}` +
      (startDate ? `&startDate=${startDate.toISOString()}` : "")
  )
}

export function getTaskProgress(taskId: string) {
  return axios.get<SyncProgressResponse>(`/api/sync/progress?taskId=${taskId}`)
}

export function getUserTasks() {
  return axios.get<UserTasksResponse>(`/api/user/tasks`)
}
